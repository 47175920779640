import { Injectable } from '@angular/core';
import { createStore, select, withProps } from '@ngneat/elf';

type AuthProps = {
  token: string | null;
  refreshToken: string | null;
};

export const AUTH_KEY = 'auth';

const defaultValues: AuthProps = {
  token: null,
  refreshToken: null,
};

const initialState = (): AuthProps => {
  if (typeof window === 'undefined') return defaultValues;

  const sessionStorageData = sessionStorage.getItem(AUTH_KEY);

  if (sessionStorageData) return JSON.parse(sessionStorageData);

  return defaultValues;
};

const authStore = createStore(
  { name: AUTH_KEY },
  withProps<AuthProps>(initialState()),
);

@Injectable({ providedIn: 'root' })
export class AuthRepository {
  token$ = authStore.pipe(select(({ token }) => token));
  refreshToken$ = authStore.pipe(select(({ refreshToken }) => refreshToken));

  setToken(token: AuthProps['token']) {
    authStore.update((state) => ({ ...state, token }));
  }

  setRefreshToken(refreshToken: AuthProps['refreshToken']) {
    authStore.update((state) => ({ ...state, refreshToken }));
  }

  setAuthDataToStorage(authData: AuthProps) {
    sessionStorage.setItem(AUTH_KEY, JSON.stringify(authData));
  }

  clearAuthData() {
    this.setToken(null);
    this.setRefreshToken(null);
    sessionStorage.removeItem(AUTH_KEY);
  }
}
